import { BrowserRouter } from 'react-router-dom';
// routes
import Router from './routes';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <BrowserRouter>
        <Router />
    </BrowserRouter>
  );
}

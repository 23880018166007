import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ChooseLocation = () => {

    const navigate = useNavigate(); // Initialize the navigate function

    const handleManadoClick = () => {
        // Use navigate to redirect to the /ParkwaysLottery page
        navigate('/ParkwaysLotteryManado');
    };

    const handleBaliClick = () => {
        // Use navigate to redirect to the /ParkwaysLottery page
        navigate('/ParkwaysLotteryBali');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '30px',
                backgroundColor: "#111",
                height: "100vh",
                maxHeight: "100vh",
                padding: "0px 30px",
                background: "rgb(250, 1, 5)",
                background:
                    "linear-gradient(180deg, rgba(250, 1, 5, 1) 0%, rgba(250, 1, 5, 1) 16%, rgba(158, 15, 50, 1) 61%, rgba(34, 34, 81, 1) 100%)",
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    height: '120px',
                    width: '300px',
                    p: '20px',
                }}
            >
                <Box mb='20px'>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '30px',
                            textAlign: 'center',
                        }}
                    >
                        Pilih Lokasi
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexDirection: 'row',
                        gap: '50px',
                    }}
                >
                    <Button
                        variant='contained'
                        onClick={handleManadoClick}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            Manado
                        </Typography>
                    </Button>

                    <Button
                        variant='contained'
                        onClick={handleBaliClick}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            Bali
                        </Typography>
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default ChooseLocation;

import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ticketData } from "../config/Api";
import { Icon } from "@iconify/react";
import WinnerModal from "../components/modal/winnerModal";
import * as XLSX from "xlsx";
import FormatTransactionCode from "../components/format/FormatTransaction";
import voucher from "../assets/voucher_belanja.png";
import sembako from "../assets/sembako.png";
import elektronik from "../assets/barang_elektronik.png";
import parkways from "../assets/icon_parkways.png";
import bss from "../assets/icon_bss.png";
import pm from "../assets/icon_pm.png";

// avatar style
const avatarSX = {
  width: 25,
  height: 25,
  fontSize: "13px",
  fontWeight: "bold",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

const ParkwaysLotteryManado = () => {
  const [participants, setParticipants] = useState([]);
  const [participantsMember, setParticipantsMember] = useState([]);
  const [initialParticipantsCount, setInitialParticipantsCount] = useState([]);
  const [initialParticipantsCountMember, setInitialParticipantsCountMember] = useState([]);
  const [winners, setWinners] = useState([]);
  const [winnersMember, setWinnersMember] = useState([]);
  const [AcakAngka, setAcakAngka] = useState(null);
  const [isRandomizing, setIsRandomizing] = useState(false);
  const [result, setResult] = useState(null);
  const [openWinnersTable, setOpenWinnersTable] = useState(false);
  const [openWinnersModal, setOpenWinnersModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("nonmember");

  const getData = async () => {
    await axios
      .get(ticketData)
      .then((response) => {
        console.log("data", response.data);
        setParticipants(response.data.nonmember);
        setInitialParticipantsCount(response.data.nonmember);
        setParticipantsMember(response.data.member);
        setInitialParticipantsCountMember(response.data.member);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handlePickRandom = () => {
    if (selectedOption === 'nonmember') {
      if (participants.length > 0) {
        const randomIndex = Math.floor(Math.random() * participants.length);
        const winner = participants[randomIndex];

        // Update winners with the new winner
        setWinners((prevWinners) => [...prevWinners, winner]);

        // Update participants after picking the winner
        setParticipants((prevParticipants) =>
          prevParticipants.filter((_, index) => index !== randomIndex)
        );

        // console.log(winner);
        setResult(winner);
        setAcakAngka(null);
        setIsRandomizing(false);
        setOpenWinnersModal(true);
      } else {
        setResult("Semua peserta telah dipilih.");
      }
    } else {
      if (participantsMember.length > 0) {
        const randomIndex = Math.floor(Math.random() * participantsMember.length);
        const winnerMember = participantsMember[randomIndex];

        // Update winners with the new winner
        setWinnersMember((prevWinnerMember) => [...prevWinnerMember, winnerMember]);

        // Update participants after picking the winner
        setParticipantsMember((prevParticipantsMember) =>
          prevParticipantsMember.filter((_, index) => index !== randomIndex)
        );

        // console.log(winner);
        setResult(winnerMember);
        setAcakAngka(null);
        setIsRandomizing(false);
        setOpenWinnersModal(true);
      } else {
        setResult("Semua peserta telah dipilih.");
      }
    }
  };

  useEffect(() => {
    let interval;
    if (isRandomizing) {
      interval = setInterval(() => {
        if (participants.length > 0) {
          const randomIndex = Math.floor(Math.random() * participants.length);
          setAcakAngka(participants[randomIndex]);
        } else {
          setIsRandomizing(false);
        }
      }, 100); // Ganti 100 dengan nilai interval (dalam milidetik) sesuai kebutuhan Anda.
    }

    return () => clearInterval(interval);
  }, [isRandomizing, participants]);

  useEffect(() => {
    getData();
  }, []);

  const handleReset = () => {
    setResult(null);
    setIsRandomizing(false);
    setAcakAngka(null);
  };

  const handleAcak = () => {
    if (participants.length > 0) {
      setIsRandomizing(true);
    } else {
      setResult("Semua peserta telah terpilih.");
    }
  };

  const handleDownload = () => {
    if (winners.length !== 0 && selectedOption === 'nonmember') {
      // Membuat objek custom header
      const exportData = winners.map((item) => ({
        "Kode Transaksi": item.transaction_code,
        "Nomor Plat": item.police_number,
      }));

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "DaftarPemenangUndianNonMember");

      XLSX.writeFile(workBook, "DaftarPemenangUndianNonMember.xlsx");
      // InfoAlert({
      //   message: 'Tersimpan di folder download',
      // });
    } else if (winnersMember.length !== 0 && selectedOption === 'member') {
      const exportData = winnersMember.map((item) => ({
        "Kode Transaksi": item.transaction_code,
        "Nomor Plat": item.police_number
      }));

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "DaftarPemenangUndianMember");

      XLSX.writeFile(workBook, "DaftarPemenangUndianMember.xlsx");
    }
    else {
      alert('Tidak ada data')
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#111",
        height: "100vh",
        maxHeight: "100vh",
        padding: "0px 30px",
        background: "rgb(250, 1, 5)",
        background:
          "linear-gradient(180deg, rgba(250, 1, 5, 1) 0%, rgba(250, 1, 5, 1) 16%, rgba(158, 15, 50, 1) 61%, rgba(34, 34, 81, 1) 100%)",
      }}
    >

      <Box align="center" >
        <Typography
          // variant="h4"
          sx={{ color: "white", fontWeight: "bold", fontSize: "50px" }}
        >
          {selectedOption === 'nonmember' ? 'UNDIAN PARKIR BERHADIAH NON-MEMBER' : 'UNDIAN PARKIR BERHADIAH MEMBER'}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        // gap="30px"
        sx={{
          mt: "-90px",
          mb: "-110px",
        }}
      >
        <Box>
          <img src={voucher} alt="Voucher" width={300} height={300} />
        </Box>
        <Box>
          <img src={sembako} alt="Sembako" width={300} height={300} />
        </Box>
        <Box>
          <img src={elektronik} alt="Elektronik" width={300} height={300} />
        </Box>
      </Box>

      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* Column 1 */}
        {/* <Grid item xs={12} md={7} lg={3}>
          
        </Grid> */}

        {/* Column 2 */}
        <Grid item xs={12} md={7} lg={12}>
          {/* Navbar */}
          {
            participants.length !== 0 ?
              <Box
                sx={{
                  display: 'flex',
                  width: '50%',
                  gap: '20px',
                  position: 'absolute',
                  zIndex: '1',
                  top: '235px',
                  left: '60px'
                }}
              // backgroundColor='orange'
              >
                <Button
                  onClick={() => setSelectedOption("nonmember")}
                  variant={selectedOption === "nonmember" ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    backgroundColor: selectedOption === "nonmember" ? '#1E458C' : '',
                    color: selectedOption === "nonmember" ? 'white' : '#1E458C'
                  }}
                >
                  Non-Member
                </Button>
                <Button
                  onClick={() => setSelectedOption("member")}
                  variant={selectedOption === "member" ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    // backgroundColor:'1E458C'
                  }}
                >
                  Member
                </Button>
              </Box>
              :
              undefined
          }

          <Card
            sx={{
              height: "49vh",
              // width: "100%",
              padding: "20px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
              // color: "#1E458C",
              // maxHeight: participantsOverflow ? "50vh" : "auto",
              // overflowY: participantsOverflow ? "scroll" : "visible",
              borderRadius: "24px",
            }}
          >
            {participants.length !== 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // gap="10px"
                height="100%"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  // flexDirection="column"
                  // backgroundColor="green"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  height="40%"
                  mt="60px"
                >
                  <Box
                    sx={{
                      ml: '100px'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bolder",
                        color: "#1E458C",
                        fontSize: "20px",
                      }}
                    >
                      Jumlah Peserta
                    </Typography>
                    {selectedOption === 'nonmember' ?
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "#1E458C",
                          fontSize: "30px",
                          textAlign: "center",
                        }}
                      >
                        {initialParticipantsCount.length
                          ? initialParticipantsCount.length
                          : 0}
                      </Typography>
                      :
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "#1E458C",
                          fontSize: "30px",
                          textAlign: "center",
                        }}
                      >
                        {initialParticipantsCountMember.length
                          ? initialParticipantsCountMember.length
                          : 0}
                      </Typography>
                    }
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#1E458C",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Mesin Acak
                    </Typography>
                    {selectedOption === 'nonmember' ?
                      <Typography
                        sx={{
                          color: "#1E458C",
                          fontSize: "70px",
                          fontWeight: "bold",
                          textAlign: "center",
                          mb: "5px",
                          mt: "-10px",
                        }}
                      >
                        {AcakAngka && AcakAngka.transaction_code
                          ? AcakAngka.transaction_code
                          : result && result.transaction_code
                            ? result.transaction_code
                            : ""}
                      </Typography>

                      :
                      <Typography
                        sx={{
                          color: "#1E458C",
                          fontSize: "70px",
                          fontWeight: "bold",
                          textAlign: "center",
                          mb: "5px",
                          mt: "-10px",
                        }}
                      >
                        {AcakAngka && AcakAngka.transaction_code
                          ? AcakAngka.transaction_code
                          : result && result.transaction_code
                            ? result.transaction_code
                            : ""}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      mr: '100px'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bolder",
                        color: "#1E458C",
                        fontSize: "20px",
                      }}
                    >
                      Peserta Tersisa
                    </Typography>
                    {selectedOption === 'nonmember' ?
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "#1E458C",
                          fontSize: "30px",
                          textAlign: "center",
                        }}
                      >
                        {participants.length ? participants.length : 0}
                      </Typography>
                      :
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "#1E458C",
                          fontSize: "30px",
                          textAlign: "center",
                        }}
                      >
                        {participantsMember.length ? participantsMember.length : 0}
                      </Typography>
                    }
                  </Box>
                </Box>
                <Box
                  display="flex"
                  // backgroundColor="gray"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="10%"
                  mb="15px"
                >
                  <Box>
                    {result ? (
                      <Box align="center">
                        <Button
                          size="medium"
                          variant="contained"
                          onClick={handleReset}
                          sx={{
                            backgroundColor: "#1E458C",
                            fontWeight: "bold",
                            fontSize: "13px",
                          }}
                        >
                          Undi Lagi
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        {isRandomizing ? (
                          <>
                            <Button
                              variant="contained"
                              size="medium"
                              onClick={() => setIsRandomizing(false)}
                              sx={{
                                backgroundColor: "#1E458C",
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                Hentikan Acak
                              </Typography>
                            </Button>
                            <Button
                              sx={{
                                marginLeft: isRandomizing ? "50px" : undefined,
                                fontWeight: "bold",
                                fontSize: "13px",
                                backgroundColor: "#1E458C",
                              }}
                              variant="contained"
                              size="medium"
                              onClick={handlePickRandom}
                              disabled={!isRandomizing}
                            >
                              Pilih Pemenang
                            </Button>
                          </>
                        ) : (
                          <Box align="center">
                            <Button
                              size="medium"
                              variant="contained"
                              onClick={handleAcak}
                              sx={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                backgroundColor: "#1E458C",
                              }}
                            >
                              Mulai Undian
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  height="5%"
                >
                  <Typography
                    sx={{
                      fontWeight: "bolder",
                      color: "#1E458C",
                      // textAlign: "start",
                      fontSize: "17px",
                      // mb: "5px",
                      ml: "20px",
                    }}
                  >
                    Daftar Pemenang
                  </Typography>
                  <Tooltip title="Download Data Pemenang">
                    <IconButton onClick={() => handleDownload()}>
                      <Icon
                        icon="line-md:download-outline-loop"
                        style={{ fontSize: "23px", color: "#1E458C" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  alignItems="start"
                  width="98%"
                  height="35%"
                  // mb='5px'
                  mt="8px"
                  // backgroundColor='orange'
                  sx={{
                    overflowY: "auto",
                  }}
                >
                  {selectedOption === 'nonmember' ?
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          "& .MuiAvatar-root": avatarSX,
                        },
                        display: "flex",
                        flexDirection: "row", // Display winners in a row
                        flexWrap: "wrap", // Allow multiple rows
                        gap: "5px", // Adjust gap between winners
                        padding: "5px",
                        // backgroundColor:'orange'
                      }}
                    >
                      {winners.map((winner, index) => {
                        const transactionCode = winner.transaction_code;
                        const formattedTransactionCode = transactionCode
                          .replace(/(.{4})/g, "$1-")
                          .slice(0, 15);

                        return (
                          <ListItemButton
                            key={index}
                            sx={{
                              p: "1px",
                              backgroundColor: "#FFEAEA",
                              borderRadius: "10px",
                              padding: "5px 10px",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: "#fff",
                                  bgcolor: "#EE2F00",
                                }}
                              >
                                {index + 1}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color: "#EE2F00",
                                    fontWeight: "bold",
                                    ml: "-20px",
                                  }}
                                >
                                  {FormatTransactionCode(winner.transaction_code)} │ {<span style={{ color: '#1E458C' }}>{winner.police_number}</span>}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                    :
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          "& .MuiAvatar-root": avatarSX,
                        },
                        display: "flex",
                        flexDirection: "row", // Display winners in a row
                        flexWrap: "wrap", // Allow multiple rows
                        gap: "5px", // Adjust gap between winners
                        padding: "5px",
                        // backgroundColor:'orange'
                      }}
                    >
                      {winnersMember.map((winner, index) => {
                        const transactionCode = winner.transaction_code;

                        return (
                          <ListItemButton
                            key={index}
                            sx={{
                              p: "1px",
                              backgroundColor: "#FFEAEA",
                              borderRadius: "10px",
                              padding: "5px 10px",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: "#fff",
                                  bgcolor: "#EE2F00",
                                }}
                              >
                                {index + 1}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color: "#EE2F00",
                                    fontWeight: "bold",
                                    ml: "-20px",
                                  }}
                                >
                                  {FormatTransactionCode(winner.transaction_code)} │ {<span style={{ color: '#1E458C' }}>{winner.police_number}</span>}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  }
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Icon
                  icon="svg-spinners:3-dots-scale"
                  style={{ fontSize: "60px", color: "#EE2F00" }}
                />
              </Box>
            )}
          </Card>
        </Grid>

        {/* Footer Logo */}
        <Grid container rowSpacing={4.5} columnSpacing={2.75} mt="15px">
          <Grid item xs={12} md={7} lg={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            // gap="30px"
            >
              <Box>
                <img src={bss} alt="bss" width={70} height={70} />
                <img src={parkways} alt="parkways" width={70} height={70} />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                mt="20px"
              >
                <Icon icon="mdi:web" style={{ color: "white" }} />
                <Typography
                  color="white"
                  fontSize="13px"
                  fontWeight="bold"
                  mr="40px"
                >
                  www.bssparking.com
                </Typography>
                <Icon icon="mdi:web" style={{ color: "white" }} />
                <Typography color="white" fontSize="13px" fontWeight="bold">
                  www.parkways.id
                </Typography>
              </Box>
              <Box>
                <img src={pm} alt="pm" width={70} height={70} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Column 3 */}
        {/* <Grid item xs={12} md={7} lg={3}>
          <Card
            sx={{
              height: "50vh",
              // width: "100%",
              padding: "10px 15px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
              // color: "#1E458C",
              // maxHeight: participantsOverflow ? "50vh" : "auto",
              // overflowY: participantsOverflow ? "scroll" : "visible",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bolder",
                color: "#1E458C",
                textAlign: "center",
                fontSize: "17px",
                mb: "10px",
              }}
            >
              Daftar Peserta
            </Typography>
            <List
              component="nav"
              sx={{
                px: 0,
                py: 0,
                "& .MuiListItemButton-root": {
                  // py: 1.5,
                  "& .MuiAvatar-root": avatarSX,
                  "& .MuiListItemSecondaryAction-root": {
                    ...actionSX,
                    position: "relative",
                  },
                },
              }}
            >
              {participants.slice(0, 8).map((participant, index) => {
                return (
                  <ListItemButton divider key={index} sx={{p:'1px'}}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          color: "#fff",
                          bgcolor: "#1E458C",
                        }}
                      >
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#1E458C", fontWeight: "bold" }}
                        >
                          {participant.transaction_code}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Card>
        </Grid> */}
      </Grid>
      <WinnerModal
        open={openWinnersModal}
        close={() => setOpenWinnersModal(false)}
        winner={result}
        switchData={selectedOption}
      />
    </Box>
  );
};

export default ParkwaysLotteryManado;

// material-ui
import { Icon } from "@iconify/react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Paper,
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TablePagination,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  List,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import FormatTransactionCode from "../format/FormatTransaction";

// avatar style
const avatarSX = {
  width: 55,
  height: 55,
  fontSize: "13px",
  fontWeight: "bold",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

const WinnerModal = ({
  open,
  close,
  winner,
  switchData,
  handleSubmit,
  selectedData,
  onChange,
  isSubmitting,
  title,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 400 : 350,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  // console.log(winner);

  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        <Box
          mb="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{ fontSize: "30px", color: "#1E458C", fontWeight: "bolder" }}
          >
            Pemenang
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mb='30px'
        >
          <Icon icon="noto-v1:trophy" style={{ fontSize: "150px" }} />
        </Box>
        <Box align="center">
          <List
            component="nav"
            sx={{
              px: 0,
              py: 0,
              "& .MuiListItemButton-root": {
                // py: 1.5,
                "& .MuiAvatar-root": avatarSX,
                "& .MuiListItemSecondaryAction-root": {
                  ...actionSX,
                  position: "relative",
                },
              },
              display: "flex",
              flexDirection: 'column',
              gap: "20px",
              // backgroundColor:"yellow",
              // flexWrap: "wrap", // Allow multiple rows
              padding: "5px",
            }}
          >
            {switchData === 'nonmember' ?
              <>
                <ListItemButton
                  sx={{
                    p: "1px",
                    backgroundColor: "#FFEAEA",
                    borderRadius: "10px",
                    // padding: "5px 10px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        // variant="subtitle1"
                        sx={{
                          color: "#EE2F00",
                          fontWeight: "bold",
                          //   ml: "10px",
                          fontSize: "50px",
                          textAlign: 'center'
                        }}
                      >
                        {winner ? FormatTransactionCode(winner.transaction_code) : undefined}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    p: "1px",
                    backgroundColor: 'rgba(30, 69, 140, 0.11)',
                    borderRadius: "10px",
                    // padding: "5px 10px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        // variant="subtitle1"
                        sx={{
                          color: "#1E458C",
                          fontWeight: "bold",
                          //   ml: "10px",
                          fontSize: "50px",
                          textAlign: 'center'
                        }}
                      >
                        {winner ? winner.police_number : undefined}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </>

              :
              <>
                <ListItemButton
                  sx={{
                    p: "1px",
                    backgroundColor: "#FFEAEA",
                    borderRadius: "10px",
                    // padding: "5px 10px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        // variant="subtitle1"
                        sx={{
                          color: "#EE2F00",
                          fontWeight: "bold",
                          //   ml: "10px",
                          fontSize: "50px",
                          textAlign: 'center'
                        }}
                      >
                        {winner ? FormatTransactionCode(winner.transaction_code) : undefined}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    p: "1px",
                    backgroundColor: 'rgba(30, 69, 140, 0.11)',
                    borderRadius: "10px",
                    // padding: "5px 10px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        // variant="subtitle1"
                        sx={{
                          color: "#1E458C",
                          fontWeight: "bold",
                          //   ml: "10px",
                          fontSize: "50px",
                          textAlign: 'center'
                        }}
                      >
                        {winner ? winner.police_number : undefined}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </>
            }
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default WinnerModal;

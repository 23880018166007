import { Navigate, useRoutes } from 'react-router-dom';

import ChooseLocation from './pages/chooselocation';
import ParkwaysLotteryManado from './pages/parkwayslotterymanado';
import ParkwaysLotteryBali from './pages/parkwayslotterybali';

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <ChooseLocation />,
        },
        {
            // path: 'dashboard',
            //   element: <DashboardLayout />,
            children: [
                { path: '/parkwayslotterymanado', element: <ParkwaysLotteryManado />, index: true },
                { path: '/parkwayslotterybali', element: <ParkwaysLotteryBali />, index: true },
                // { path: '/user', element: <UserPage /> },
            ],
        },
    ]);

    return routes;
}


